import React, { useEffect, useState } from "react";

import { useStateContext } from "../../../../providers/stateContext";
import { useReportContext } from "../../../../providers/reportContext";
import { useConsumerUnitContext } from "../../../../providers/consumerUnitContext";

import { DatePickerCalendarRange } from "../../../../components/Form/DatePicker";

import { DateUtils } from "../../../../util/dateUtils";
import { ConsumerUnitType, equip } from "../../../../util/types";

import Select from 'react-select';

import BarChartForDays from "./BarChartForDays";

import styles from "./styles.module.scss";
import { useMeterContext } from "../../../../providers/meterContext";

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#44521B' : '#44521B',
    padding: '0.3125rem 0.625rem',
    borderRadius: '0.5rem',
    margin: '5px 0',
    fontWeight: '300',
    border: '0.5px solid #C9DA9A',
    backgroundColor: state.isSelected ? '#EAF2F6' : 'transparent',
    '&:hover': {
      backgroundColor: '#97B73C',
      borderRadius: '8px',
    },
  }),
  control: (provided: any) => ({
    ...provided,
    display: 'inline-flex',
    padding: '0rem 0rem 0rem 1rem',
    marginRight: window.innerWidth <= 600 ? '10px' : '20px',
    marginLeft: window.innerWidth <= 600 ? '10px' : '10px',


    height: window.innerWidth <= 480 ? '30px' : '44px',
    width: window.innerWidth <= 360 ? '145px' : window.innerWidth <= 414 ? '160px' : window.innerWidth <= 600 ? '187px' : '199px',
    borderRadius: '73px', // Radius for rounded corners
    backgroundColor: '#FFF',
    zIndex: 10,
    border: 'none',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Leve sombra ao redor do controle
    fontFamily: 'Inter, sans-serif',
    fontWeight: '300',
    fontSize: window.innerWidth <= 414 ? '14px' : window.innerWidth <= 600 ? '16px' : '20px',
    color: '#14394C',
    transition: 'all 0.3s',

    paddingRight: '7px', // Padding Right

    paddingLeft: '10px', // Padding Left

  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 20,
    border: '0.5px solid black',
    padding: '8px',
    boxSizing: 'border-box',
    borderRadius: '25px',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Inter, sans-serif',
    color: '#14394C',
    fontWeight: 600,
    fontSize: window.innerWidth <= 414 ? '14px' : window.innerWidth <= 600 ? '16px' : '20px',
    whiteSpace: 'nowrap',   // Impede quebra de linha
    overflow: 'hidden',     // Esconde o texto excedente
    textOverflow: 'ellipsis', // Adiciona reticências ao final do texto
    maxWidth: '150px',      // Define a largura máxima para truncamento (ajuste conforme necessário)
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '2rem',
    height: '2rem',
    borderRadius: '1.5rem',
    backgroundColor: '#EAF2F6',
    // padding: '0.75rem',
    cursor: 'pointer',
    gap: '0.225rem',
    transition: 'background-color 0.3s',
    color: '#14394C',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

interface BarComparisonProps { }

export default function BarComparison({ }: BarComparisonProps) {
  const { selectConsumerUnitId, sectorId, dateToCallApiForChartBarRange } = useStateContext();
  const { reportEquipmentForDaysList, getReportEquipmentForDays, getReportSmartMeterForDays, reportSmartMeterForDaysList } = useReportContext();
  const { consumerUnitList, consumerUnitData } = useConsumerUnitContext();
  const { meterList } = useMeterContext();

  const [selectedEquipment, setSelectedEquipment] = useState<any>(null);
  const [dataChart, setDataChart] = useState<any>(undefined);

  // if the user is admin ROLE, we dont have the consumerUnitData in the context, only the consumerUnitList
  const consumerUnitSelected: ConsumerUnitType = consumerUnitList?.find((unit: ConsumerUnitType) => unit.id === selectConsumerUnitId) ?? consumerUnitData;

  // -1 is the id of the option "Todos" in the sector select, so if the id is -1, it will show all equipments
  const equipmentList = sectorId === -1 ? consumerUnitSelected?.equipments : consumerUnitSelected?.equipments?.filter((equip: equip) => equip.equipmentModelId === sectorId);

  // setar primeiro equipamento da lista como selecionado
  useEffect(() => {
    if (equipmentList || meterList?.smart?.length > 0) {
      const smartMeter = meterList?.smart?.length > 0 ? meterList.smart[0] : null;

      // Prioriza o smart meter caso exista
      const defaultEquipment = smartMeter || (equipmentList ? equipmentList[0] : null);

      if (defaultEquipment) {
        setSelectedEquipment({
          value: defaultEquipment.id,
          label: defaultEquipment.name,
        });
      }
    }
  }, [selectConsumerUnitId, consumerUnitSelected, meterList]);


  // if the sector changes, we have to recaulculate the selected equipment
  useEffect(() => {
    if (equipmentList) {
      const firstEquipment = equipmentList[0];

      if (firstEquipment) {
        setSelectedEquipment({
          value: firstEquipment?.id,
          label: firstEquipment?.name
        });
      } else {
        setSelectedEquipment(null);
      }
    }
  }, [sectorId]);

  const arrayOfSmarts = meterList?.smart;
  const isSmartSelected = arrayOfSmarts?.some((smart: any) => smart.id === selectedEquipment?.value);

  useEffect(() => {
    const formatStart = DateUtils.formatDateToISO(dateToCallApiForChartBarRange.start);

    const endDate = new Date(dateToCallApiForChartBarRange.end);
    endDate.setDate(endDate.getDate() + 1); // Adicionamos um dia na data que saiu do calendario porque o backend só retorna até meia noite de tal data, entao se o cliente escolhe dia 6, e for dia 6 10 da manha, não retorna os dados do dia 6 e ai ele não plota no gráfico, dessa forma ele pega até o dia 7 00:00, o que signfica o dia 6 inteiro.
    const formatEnd = DateUtils.formatDateToISO(endDate);

    if (selectedEquipment && formatStart && formatEnd) {

      if (isSmartSelected) {
        getReportSmartMeterForDays(
          selectConsumerUnitId,
          formatStart,
          formatEnd
        );
      }
      else {
        getReportEquipmentForDays(
          selectedEquipment.value,
          formatStart,
          formatEnd
        );
      }
    } else {
      setDataChart(undefined);
    }

  }, [dateToCallApiForChartBarRange, selectedEquipment]);

  useEffect(() => {
    setDataChart(undefined);
    if (reportEquipmentForDaysList && !isSmartSelected) {
      setDataChart(reportEquipmentForDaysList);
    }

    if (reportSmartMeterForDaysList && isSmartSelected) {
      setDataChart(reportSmartMeterForDaysList);
    }
  }, [reportEquipmentForDaysList, reportSmartMeterForDaysList]);

  const smartMeter = meterList?.smart?.length > 0 ? meterList.smart[0] : null;
  const equipmentsThatBelongToSector = sectorId === -1 ? consumerUnitSelected?.equipments : consumerUnitSelected?.equipments?.filter((equip) => equip.equipmentModelId === sectorId);

  const equipmentsThatBelongToSectorPlusSmartMeter = smartMeter && equipmentsThatBelongToSector
    ? [smartMeter, ...equipmentsThatBelongToSector]
    : equipmentsThatBelongToSector;

  const sortedEquipments = equipmentsThatBelongToSectorPlusSmartMeter
    ? equipmentsThatBelongToSectorPlusSmartMeter.sort((a, b) => {
      if (a.id === smartMeter?.id) return -1;
      if (b.id === smartMeter?.id) return 1;
      return a.name.localeCompare(b.name);
    })
    : [];

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength).trim();
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  };

  return (
    <div className={styles.chartContainer}>

      <div className={styles.dateFormattedContainer}>

        <span className={styles.areaDate}>{DateUtils.formatDateToBrazilian(dateToCallApiForChartBarRange?.start) + " - " + DateUtils.formatDateToBrazilian(dateToCallApiForChartBarRange?.end)}</span>

        <div className={styles.equipmentSelectAndDatePickerContainer}>
          <Select
            placeholder="Carregando..."
            value={selectedEquipment}
            onChange={(e) => setSelectedEquipment(e)}
            options={
              sortedEquipments?.length > 0
                ? sortedEquipments.map((equip: any) => ({
                  value: equip.id,
                  label: (
                    <span style={{ fontWeight: equip.id === smartMeter?.id ? "bold" : "inherit" }}>
                      {truncateText(equip.name, 20)}
                      {equip.id === smartMeter?.id && <span style={{ fontWeight: "bold" }}> (QGBT)</span>}
                    </span>
                  ),
                }))
                : [{ value: "", label: "Aguardando dados..." }]
            }
            styles={customStyles}
          />

          <DatePickerCalendarRange typeChart={'bar'} />

        </div>

      </div>

      <BarChartForDays
        data={dataChart}
        chartSizeType={"normal"}
        typeFormatDate="days"
      />

    </div>
  )
};