import React, { createContext, ReactNode, useContext, useState } from "react"
import {
  getReportConsumerUnitForHoursAPI, getReportEquipmentForIntervalAPI, getReportConsumerUnitForIntervalAPI,
  getReportEquipmentForDaysAPI, getReportEquipmentForHoursAPI, getReportSmartMeterForDaysAPI, getReportSmartMeterForHoursAPI, getReportConsumerUnitDaysBySectorForPizzaAPI, getReportConsumerUnitHoursBySectorForPizzaAPI
} from "../services/restApiReport"

type PropsReportContext = {
  reportEquipmentForHoursList: any,
  reportEquipmentForDaysList: any,
  reportSmartMeterForHoursList: any,
  reportSmartMeterForDaysList: any,
  reportEquipmentForIntervalArea: any,
  reportEquipmentForIntervalBar: any,
  reportConsumerUnitForIntervalArea: any,
  reportConsumerUnitForHoursList: {},
  reportConsumerUnitForHoursPie: {},
  reportConsumerUnitForHoursArea: {},
  reportConsumerUnitForIntervalBar: any,

  loadingChartBar: boolean,
  loadingChartPie: boolean,
  loadingChart: boolean,
  setLoadingChartBar: React.Dispatch<React.SetStateAction<boolean>>,
  setLoadingChart: React.Dispatch<React.SetStateAction<boolean>>,
  getReportEquipmentForHours: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportEquipmentForDays: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportEquipmentForInterval: (id: string | number, interval: string, startDate: string, endDate: string, typeChart: string) => Promise<void>,
  getReportConsumerUnitForInterval: (id: number | string, interval: string, startTime: string, endTime: string, typeChart: string, sectorId?: number) => Promise<void>,
  getReportSmartMeterForHours: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportSmartMeterForDays: (id: string | number, startDate: string, endDate: string) => Promise<void>,
  getReportConsumerUnitForHours: (id: string | number, startDate: string, endDate: string, typeChart: string) => Promise<void>,
  getReportConsumerUnitDaysBySectorForPizza: (id: string | number, startDate: string, endDate: string, equipmentModelId: number) => Promise<void>,
  getReportConsumerUnitHoursBySectorForPizza: (id: string | number, startDate: string, endDate: string, equipmentModelId: number) => Promise<void>
}

type MeterContextTypes = {
  children: ReactNode
}

export const ReportContext = createContext({} as PropsReportContext)

function ReportContextProvider(props: MeterContextTypes) {
  const [reportEquipmentForHoursList, setReportEquipmentForHoursList] = useState<any>()
  const [reportEquipmentForDaysList, setReportEquipmentForDaysList] = useState<any>()
  const [reportEquipmentForIntervalArea, setReportEquipmentForIntervalArea] = useState<any>()
  const [reportEquipmentForIntervalBar, setReportEquipmentForIntervalBar] = useState<any>()
  const [reportSmartMeterForHoursList, setReportSmartMeterForHoursList] = useState<any>()
  const [reportSmartMeterForDaysList, setReportSmartMeterForDaysList] = useState<any>()
  const [reportConsumerUnitForHoursList, setReportConsumerUnitForHoursList] = useState({})
  const [reportConsumerUnitForHoursPie, setReportConsumerUnitForHoursPie] = useState<any>({})
  const [reportConsumerUnitForHoursArea, setReportConsumerUnitForHoursArea] = useState({})
  const [reportConsumerUnitForIntervalArea, setReportConsumerUnitForIntervalArea] = useState<any>({})
  const [reportConsumerUnitForIntervalBar, setReportConsumerUnitForIntervalBar] = useState<any>({})
  const [loadingChartBar, setLoadingChartBar] = useState(true)
  const [loadingChartPie, setLoadingChartPie] = useState(true)
  const [loadingChart, setLoadingChart] = useState(true)

  const getReportEquipmentForHours = async (id: string | number, startDate: string, endDate: string) => {
    try {
      const { data, status } = await getReportEquipmentForHoursAPI(id, startDate, endDate);
      if (status === 200) {
        setReportEquipmentForHoursList(data);
      } else {
        setReportEquipmentForHoursList(null);
      }
    } catch (error) {
      setReportEquipmentForHoursList(null);
    } finally {
    }
  };

  const getReportEquipmentForDays = async (id: string | number, startDate: string, endDate: string) => {
    setLoadingChartBar(true)

    const { data, status } = await getReportEquipmentForDaysAPI(id, startDate, endDate)
    if (status === 200) {
      setReportEquipmentForDaysList(data)
      setLoadingChartBar(false)
    } else {
      setReportEquipmentForDaysList(null)
      setLoadingChartBar(false)
    }
  }

  const getReportEquipmentForInterval = async (id: string | number, interval: string, startDate: string, endDate: string, typeChart: string) => {
    if (typeChart === "area") {
      const { data, status } = await getReportEquipmentForIntervalAPI(id, interval, startDate, endDate)
      if (status === 200) {
        setReportEquipmentForIntervalArea(data)
      } else {
        setReportEquipmentForIntervalArea(null)
      }
    }
    if (typeChart === "bar") {
      const { data, status } = await getReportEquipmentForIntervalAPI(id, interval, startDate, endDate)
      if (status === 200) {
        setReportEquipmentForIntervalBar(data)
      } else {
        setReportEquipmentForIntervalBar(null)
      }
    }
  }

  const getReportSmartMeterForHours = async (id: string | number, startDate: string, endDate: string) => {

    const { data, status } = await getReportSmartMeterForHoursAPI(id, startDate, endDate)
    if (status === 200) {
      setReportSmartMeterForHoursList(data)
    } else {
      setReportSmartMeterForHoursList(null)
    }
  }

  const getReportSmartMeterForDays = async (id: string | number, startDate: string, endDate: string) => {
    setLoadingChartBar(true);
    const { data, status } = await getReportSmartMeterForDaysAPI(id, startDate, endDate)
    if (status === 200) {
      setReportSmartMeterForDaysList(data)
    } else {
      setReportSmartMeterForDaysList(null)
    }
    setLoadingChartBar(false);
  }

  const getReportConsumerUnitForHours = async (id: string | number, startDate: string, endDate: string, typeChart: string) => {
    typeChart === "pie" ? setLoadingChartPie(true) : setLoadingChart(true)

    const { data, status } = await getReportConsumerUnitForHoursAPI(id, startDate, endDate)
    if (status === 200) {
      if (typeChart === "pie") {
        setLoadingChartPie(false)
        setReportConsumerUnitForHoursPie(data)
      }
      if (typeChart === "area") {
        setLoadingChart(false)
        setReportConsumerUnitForHoursArea(data)
      }
      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)

      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)
    } else {
      setLoadingChart(false)
      setReportConsumerUnitForHoursList(data)
    }
  }

  const getReportConsumerUnitForInterval = async (
    id: string | number,
    interval: string,
    startDate: string,
    endDate: string,
    typeChart: string,
    sectorId?: number
  ) => {


    if (typeChart === "area") {
      setLoadingChart(true);
    } else if (typeChart === "bar") {
      setLoadingChartBar(true);
      setReportConsumerUnitForIntervalBar({});
    }

    try {
      const { data, status } = await getReportConsumerUnitForIntervalAPI(id, interval, startDate, endDate, sectorId);

      if (status === 200) {
        if (typeChart === "area") {
          setLoadingChart(false);
          setReportConsumerUnitForIntervalArea(data);
        } else if (typeChart === "bar") {
          setLoadingChartBar(false);
          setReportConsumerUnitForIntervalBar(data);
        }
      } else {
        console.log(`Erro inesperado: ${status} - ${data?.message}`);
      }
    } catch (error) {
      if (error instanceof Error && 'response' in error) {
        const err = error as any;
        console.error("Erro ao buscar dados:", err.response.data);
      } else {
        console.error("Erro ao buscar dados:", error instanceof Error ? error.message : "Erro desconhecido");
      }

      if (typeChart === "area") {
        setLoadingChart(false);
        setReportConsumerUnitForIntervalArea({});
        console.log("Erro: definindo dados vazios para gráfico de área.");
      } else if (typeChart === "bar") {
        setLoadingChartBar(false);
        setReportConsumerUnitForIntervalBar({});
        console.log("Erro: definindo dados vazios para gráfico de barras.");
      }
    } finally {
      if (typeChart === "area") {
        setLoadingChart(false);
      } else if (typeChart === "bar") {
        setLoadingChartBar(false);
      }
    }
  };

  const getReportConsumerUnitDaysBySectorForPizza = async (id: string | number, startDate: string, endDate: string, equipmentModelId: number) => {
    setLoadingChartPie(true);
    try {
      const { data, status } = await getReportConsumerUnitDaysBySectorForPizzaAPI(id, startDate, endDate, equipmentModelId);
      if (status === 200) {
        setReportConsumerUnitForHoursPie(data);
      } else {
        setReportConsumerUnitForHoursPie(null);
      }
    } catch (error) {
      setReportConsumerUnitForHoursPie(null);
    } finally {
      setLoadingChartPie(false);
    }
  }

  const getReportConsumerUnitHoursBySectorForPizza = async (id: string | number, startDate: string, endDate: string, equipmentModelId: number) => {
    setLoadingChartPie(true);
    try {
      const { data, status } = await getReportConsumerUnitHoursBySectorForPizzaAPI(id, startDate, endDate, equipmentModelId);
      if (status === 200) {
        setReportConsumerUnitForHoursPie(data);
      } else {
        setReportConsumerUnitForHoursPie(null);
      }
    } catch (error) {
      setReportConsumerUnitForHoursPie(null);
    } finally {
      setLoadingChartPie(false);
    }
  }


  const objProvider = {
    loadingChartBar,
    loadingChartPie,
    loadingChart,
    setLoadingChartBar,
    setLoadingChart,
    reportEquipmentForHoursList,
    reportEquipmentForDaysList,
    reportSmartMeterForHoursList,
    reportSmartMeterForDaysList,
    reportConsumerUnitForHoursList,
    reportConsumerUnitForHoursPie,
    reportConsumerUnitForHoursArea,
    reportEquipmentForIntervalArea,
    reportEquipmentForIntervalBar,
    reportConsumerUnitForIntervalArea,
    reportConsumerUnitForIntervalBar,

    getReportConsumerUnitForInterval,
    getReportEquipmentForHours,
    getReportEquipmentForDays,
    getReportSmartMeterForHours,
    getReportSmartMeterForDays,
    getReportConsumerUnitForHours,
    getReportEquipmentForInterval,
    getReportConsumerUnitDaysBySectorForPizza,
    getReportConsumerUnitHoursBySectorForPizza
  }

  return (
    <ReportContext.Provider
      value={objProvider}
    >
      {props.children}
    </ReportContext.Provider>
  )
}

function useReportContext() {
  const context = useContext(ReportContext)
  return context
}

export {
  ReportContextProvider,
  useReportContext
}